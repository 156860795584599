<template>
  <section class="map-item">
    <v-card class="card" dark>
      <v-card-title class="card-title grey darken-4">
        <section class="w-100 d-flex justify-space-between align-center">
          <h3 class="white--text">Location details</h3>
          <v-btn color="white" outlined icon small @click="close">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </section>
      </v-card-title>
      <v-card-text class="py-3 card-text">
        <section class="location-details">
          <section class="d-flex my-2">
            <span class="mr-1 yellow--text">
              Assigned Land:
              <span class="grey--text">{{
                assignLandRender(selectedItem)
              }}</span></span
            >
            <v-spacer></v-spacer>
            <span class="mr-1 yellow--text">
              ID: <span class="grey--text">{{ selectedItem.id }}</span></span
            >
          </section>
          <div class="d-flex justify-space-between my-4">
            <div class="details">
              <section class="my-3">
                <div class="mr-3 yellow--text" style="min-width: 80px">
                  Coordinate X:
                  <span class="grey--text">{{
                    selectedItem.tile.coordinate_x
                  }}</span>
                </div>
                <div class="mr-1 yellow--text" style="min-width: 80px">
                  Coordinate Y:
                  <span class="grey--text">{{
                    selectedItem.tile.coordinate_y
                  }}</span>
                </div>
              </section>
              <section class="mb-5">
                <div class="mr-3 yellow--text" style="min-width: 80px">
                  Category name:
                  <span class="grey--text">{{
                    selectedItem.category.title
                  }}</span>
                </div>
                <div class="mr-1 yellow--text" style="min-width: 80px">
                  category id:
                  <span class="grey--text">{{ selectedItem.category.id }}</span>
                </div>
              </section>
            </div>
            <v-img
              class=""
              :src="`https://test.landsofazolite.com/api/${selectedItem.category.art_portrait}`"
              max-width="220"
              max-height="220"
            ></v-img>
          </div>
        </section>

        <v-expansion-panels v-model="panel" v-if="!selectedItem.land">
            <v-expansion-panel :disabled="!token">
              <v-expansion-panel-header class="orange--text"
                >{{token ? "Assign land to this location" : "To assign land to this location you need to Login first"}}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="tags">
                  <section class="">
                    <h5 class="grey--text mb-2">
                      To assign your lands to this location select your land
                      first.
                    </h5>
                    <v-form ref="assignForm">
                      <v-select
                        v-model="selectedLand"
                        :items="finallLands"
                        :loading="getLandsLoading"
                        item-value="id"
                        label="User land List"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="rules.select"
                        outlined
                        dense
                        clearable
                        dark
                      >
                        <template v-slot:selection="{ item }">
                          {{ getText(item) }}
                        </template>
                        <template v-slot:item="{ item }">
                          {{ getText(item) }}
                        </template>
                      </v-select>
                      <div class="actions d-flex justify-end">
                        <v-btn
                          color="#ff6600"
                          min-width="150"
                          class="black--text"
                          style="font-weight: 900"
                          :loading="isLoading"
                          @click="assignLandtoThisLocation"
                        >
                          Assign to this location
                        </v-btn>
                      </div>
                    </v-form>
                  </section>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <h3 class="grey darken-4 pa-3 br-5 text-center orange--text" v-else>
          This location assigned to land id: {{ selectedItem.land.id }}
        </h3>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["selectedItem", "finallLands", "getLandsLoading"],
  data() {
    return {
      isLoading: false,
      selectedLand: null,
      panel: 1,
    };
  },
  computed: {
    ...mapGetters(["rules", "token"]),
  },
  methods: {
    ...mapActions(["getUserLands", "assignLandToLocation"]),
    getText(item) {
      return ` ID:${item.id} --- ${item.name}`;
    },
    close() {
      if (this.panel == 0 && !this.selectedItem.land) {
        this.$refs.assignForm.reset();
      }
      this.$emit("close");
    },

    assignLandtoThisLocation() {
      if (this.$refs.assignForm.validate()) {
        const info = {
          land_id: this.selectedLand,
          coordinate_x: this.selectedItem.tile.coordinate_x,
          coordinate_y: this.selectedItem.tile.coordinate_y,
        };
        this.assignLandToLocation(info)
          .then(() => {
            this.$emit("success");
          })
          .catch(() => {
            this.$emit("error");
          })
          .finally(() => {
            this.$refs.assignForm.reset();
            this.$refs.assignForm.resetValidation();
          });
      }
    },
    assignLandRender(item) {
      if (!item.land) {
        return "Free for assign";
      } else {
        return `Id: ${item.land.id}, name: ${item.land.name}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-item {
  .card {
    background: black;
  }
}
</style>
