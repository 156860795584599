<template>
  <section class="map routes">
    <v-row no-gutters class="">
      <v-col cols="12 ">
        <section class="mx-auto map-content">
          <div v-for="(item, i) in showBoxItems" :key="i" class="items">
            <div class="item" v-if="item" @dblclick="freeZoom(item, $event)">
              <v-img
                :src="item.path"
                loading="lazy"
                lazy-src="https://dummyimage.com/400x225/8f8f8f/fff&text=Loading..."
              >
                <!-- <div style="user-select: none">
                  (X:{{ item.indexX }} , Y:{{ item.indexY }})
                </div>
                <div>
                  (CordinateX:{{ item.coordinateX }} , CordinateY:{{
                    item.coordinateY
                  }})
                </div>
                <div>(width:{{ item.width }} , height:{{ item.height }})</div> -->
              </v-img>
            </div>
            <div
              class=""
              v-else
            >
              <v-img src="../../assets/images/map-border1.jpg"></v-img>
            </div>
          </div>
          <div class="zoom-box d-flex flex-column">
            <button
              @click="zoomIn"
              :class="`${zoomLevel >= 4 || zoomInIsDisable ? 'disable' : ''}`"
              :disabled="zoomLevel >= 4 || zoomInIsDisable"
            >
              <v-icon color="white" large>mdi-magnify-plus-outline</v-icon>
            </button>
            <v-divider class="grey my-1"></v-divider>
            <button
              @click="zoomOut"
              :class="`${zoomLevel <= 1 || zoomOutIsDisable ? 'disable' : ''}`"
              :disabled="zoomLevel <= 1 || zoomOutIsDisable"
            >
              <v-icon color="white" large>mdi-magnify-minus-outline</v-icon>
            </button>
            <v-divider class="grey my-1"></v-divider>
            <div class="level mx-auto white--text">
              {{ zoomLevel }}<v-icon x-small color="white">mdi-close</v-icon>
            </div>
          </div>
          <div class="pan-box d-flex flex-column align-center">
            <button
              @click="pan('top')"
              :class="`${disableTop ? 'disable' : ''}`"
              :disabled="disableTop"
            >
              <v-icon color="white">mdi-arrow-up-thick</v-icon>
            </button>
            <div class="d-flex">
              <button
                class="mx-1"
                @click="pan('left')"
                :class="`${disableLeft ? 'disable' : ''}`"
                :disabled="disableLeft"
              >
                <v-icon color="white">mdi-arrow-left-thick</v-icon>
              </button>
              <button
                class="mx-1"
                @click="pan('right')"
                :class="`${disableRight ? 'disable' : ''}`"
                :disabled="disableRight"
              >
                <v-icon color="white">mdi-arrow-right-thick</v-icon>
              </button>
            </div>
            <button
              @click="pan('down')"
              :class="`${disableDown ? 'disable' : ''}`"
              :disabled="disableDown"
            >
              <v-icon color="white">mdi-arrow-down-thick</v-icon>
            </button>
          </div>
          <div class="detail-container">
            <div
              class="detail"
              v-for="(item, i) in detailItems"
              :key="i"
              @click="selectLocation(item)"
              :style="`width:${(400 / 92.38) * 12.45}px;height:${
                (225 / 52.6) * 12.45
              }px;left:${handelX(item)}px;top:${handelY(item)}px;`"
            >
            {{i}}
              <v-img
                class="details-image"
                :style="item.land ? 'opacity:1' : ''"
                :src="`https://test.landsofazolite.com/api/${item.category.art_portrait}`"
              ></v-img>
              <v-icon>mdi-information-outline</v-icon>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <!--map item dialog -->
    <v-dialog v-model="mapItemDialog" width="580">
      <MapItem
        @close="mapItemDialog = false"
        @success="assignLandSuccess"
        @error="assignLandError"
        :selectedItem="selectedItem"
        :finallLands="finallLands"
        :getLandsLoading="getLandsLoading"
      ></MapItem>
    </v-dialog>
    <!--map item dialog -->

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green accent-4 "
      dense
      centerd
      shaped
      top
      transition="slide-y-transition"
      style="position: fixed; z-index: 10000"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import MapItem from "@/components/map/MapItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      row1: [],
      row2: [],
      row3: [],
      indexRows: [
        {
          i: 6,
          j: 9,
        },
        {
          i: 11,
          j: 14,
        },
        {
          i: 16,
          j: 19,
        },
      ],
      showBoxItems: [],
      zoomLevel: 1,
      zoomInIsDisable: false,
      zoomOutIsDisable: false,
      detailItems: [],
      finallDifferentX: null,
      finallDifferentY: null,
      offsetX: null,
      offsetY: null,

      mapItemDialog: false,
      selectedItem: {},
      finallLands: [],
      snackbar: false,
      message: "",
      getLandsLoading:false,
    };
  },
  components: {
    MapItem,
  },
  computed: {
    ...mapGetters(["token"]),
    disableLeft() {
      return (
        this.showBoxItems[3] === null ||
        this.indexRows[0].i === 0 ||
        this.indexRows[0].i === 5 ||
        this.indexRows[0].i === 10 ||
        this.items[10] === null
      );
    },
    disableRight() {
      return (
        this.showBoxItems[5] === null ||
        this.indexRows[0].j === 5 ||
        this.indexRows[0].j === 10 ||
        this.indexRows[0].j === 15 ||
        this.items[14] === null
      );
    },
    disableTop() {
      return (
        this.showBoxItems[1] === null ||
         this.indexRows[0].i === 0 ||
        this.indexRows[0].i === 1 ||
        this.indexRows[0].i === 2 ||
        this.items[2] === null
      );
    },
    disableDown() {
      return (
        this.showBoxItems[7] === null ||
        this.indexRows[2].i === 20 ||
        this.indexRows[2].i === 21 ||
        this.indexRows[2].i === 22 ||
        this.items[22] === null
      );
    },
  },
  created() {
    if(!this.$route.query.coordinate_x && !this.$route.query.coordinate_y){
      this.getMapItems({
        coordinateX: 3741,
        coordinateY: 2130,
        zoom_level: 1,
      }).then((result) => {
        this.items = result.result;
        this.showBoxItemsGenerate(
          this.indexRows[0].i,
          this.indexRows[0].j,
          this.indexRows[1].i,
          this.indexRows[1].j,
          this.indexRows[2].i,
          this.indexRows[2].j
        );
      });
    }else{
      // let urlParams = new URLSearchParams(window.location.search);
      // urlParams.delete('coordinate_x')
      // urlParams.delete('coordinate_y')
      const coordinate_x = this.$route.query.coordinate_x;
      const coordinate_y = this.$route.query.coordinate_y;
      console.log(coordinate_x,coordinate_y);
      this.zoomLevel = 4;
      this.getMapItems({
        coordinateX: coordinate_x,
        coordinateY: coordinate_y,
        zoom_level: this.zoomLevel,
      }).then((result) => {
        this.items = result.result;
        this.showBoxItemsGenerate(
          this.indexRows[0].i,
          this.indexRows[0].j,
          this.indexRows[1].i,
          this.indexRows[1].j,
          this.indexRows[2].i,
          this.indexRows[2].j
        );
      this.checkIfWeAreOnBorder();
      this.handleDetails();
      // this.$router.replace("/map")
      });
    }
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.code === "ArrowUp") {
        this.pan("top");
      } else if (e.code === "ArrowDown") {
        this.pan("down");
      } else if (e.code === "ArrowRight") {
        this.pan("right");
      } else if (e.code === "ArrowLeft") {
        this.pan("left");
      }
    });
  },
  methods: {
    ...mapActions(["getMapItems", "getDetails", "getUserLands"]),

    zoomIn() {
      if (1 <= this.zoomLevel && this.zoomLevel < 4) {
        const centerItem = this.showBoxItems[4];
        this.zoomLevel++;
        this.zoomInIsDisable = true;
        this.getMapItems({
          coordinateX: parseFloat(centerItem.coordinateX) + parseFloat(centerItem.width / 2),
          coordinateY: parseFloat(centerItem.coordinateY) + parseFloat(centerItem.height / 2),
          zoom_level: this.zoomLevel,
        }).then((result) => {
          this.items = result.result;
          this.zoomInIsDisable = false;
          this.showBoxItems = [];
          this.showBoxItemsGenerate(
            this.indexRows[0].i,
            this.indexRows[0].j,
            this.indexRows[1].i,
            this.indexRows[1].j,
            this.indexRows[2].i,
            this.indexRows[2].j
          );
          console.log(this.showBoxItems);
          this.checkIfWeAreOnBorder();
          this.handleDetails();
        });
      }
    },
    zoomOut() {
      if (1 < this.zoomLevel && this.zoomLevel <= 4) {
        const centerItem = this.showBoxItems[4];
        this.zoomLevel--;
        this.zoomOutIsDisable = true;
        this.getMapItems({
          coordinateX: parseFloat(centerItem.coordinateX) + parseFloat(centerItem.width / 2),
          coordinateY: parseFloat(centerItem.coordinateY) + parseFloat(centerItem.height / 2),
          zoom_level: this.zoomLevel,
        }).then((result) => {
          this.items = result.result;
          this.zoomOutIsDisable = false;
          this.showBoxItems = [];
          this.showBoxItemsGenerate(
            this.indexRows[0].i,
            this.indexRows[0].j,
            this.indexRows[1].i,
            this.indexRows[1].j,
            this.indexRows[2].i,
            this.indexRows[2].j
          );
          this.detailItems = [];
          console.log(this.showBoxItems);
          this.checkIfWeAreOnBorder()
        });
      }
    },
    handleDetails() {
      if (this.zoomLevel === 4) {
        const firstItem = this.showBoxItems[0];
        const lastItem = this.showBoxItems[this.showBoxItems.length - 1];
        const start_coordinatec_x = firstItem.coordinateX;
        const start_coordinate_y = firstItem.coordinateY;
        const end_coordinate_x =
          parseFloat(lastItem.coordinateX) + parseFloat(lastItem.width);
        const end_coordinate_y =
          parseFloat(lastItem.coordinateY) + parseFloat(lastItem.height);
        this.getDetails({
          start_coordinate_x: start_coordinatec_x,
          start_coordinate_y: start_coordinate_y,
          end_coordinate_x: end_coordinate_x,
          end_coordinate_y: end_coordinate_y,
        })
          .then((result) => {
            this.detailItems = result.result;
            console.log("detail-items:", this.detailItems);
            this.findCoordinateX(
              this.showBoxItems,
              this.detailItems[0].map.coordinate_x
            );
            this.findCoordinateY(
              this.showBoxItems,
              this.detailItems[0].map.coordinate_y
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    findCoordinateX(array, target) {
      const differents = [];
      array.forEach((element) => {
        const different = parseFloat(target) - parseFloat(element.coordinateX);
        if (Math.sign(different) === 1) {
          differents.push(different);
        }
      });
      let sortedArray = differents.sort((a, b) => a - b);
      const finallDifferent = sortedArray[0];
      const findedElement = array.find(
        (element) =>
          parseFloat(element.coordinateX) ===
          parseFloat(target) - parseFloat(finallDifferent)
      );
      this.finallDifferentX = finallDifferent;
      const finallElement = this.showBoxItems.indexOf(findedElement);
      console.log(finallElement);
      if (finallElement === 0) {
        this.offsetX = 0;
      } else if (finallElement === 1) {
        this.offsetX = 92.38;
      } else if (finallElement === 2) {
        this.offsetX = 92.38 * 2;
      }
      console.log("offsetX:", this.offsetX);
    },
    findCoordinateY(array, target) {
      const differents = [];
      array.forEach((element) => {
        const different = parseFloat(target) - parseFloat(element.coordinateY);
        if (Math.sign(different) === 1) {
          differents.push(different);
        }
      });
      let sortedArray = differents.sort((a, b) => a - b);
      const finallDifferent = sortedArray[0];
      const findedElement = array.find(
        (element) =>
          parseFloat(element.coordinateY) ===
          parseFloat(target) - parseFloat(finallDifferent)
      );
      this.finallDifferentY = finallDifferent;
      const finallElement = this.showBoxItems.indexOf(findedElement);
      console.log("element y:", finallElement);
      if (finallElement === 0) {
        this.offsetY = 0;
      } else if (finallElement === 3) {
        this.offsetY = 52.6;
      } else if (finallElement === 6) {
        this.offsetY = 52.6 * 2;
      }
      console.log("offsetY:", this.offsetY);
    },

    handelX(item) {
      return (
        ((parseFloat(item.map.coordinate_x) -
          this.detailItems[0].map.coordinate_x +
          this.offsetX +
          this.finallDifferentX) *
          400) /
        this.showBoxItems[0].width
      );
    },
    handelY(item) {
      return (
        ((parseFloat(item.map.coordinate_y) -
          this.detailItems[0].map.coordinate_y +
          this.offsetY +
          this.finallDifferentY) *
          225) /
        this.showBoxItems[0].height
      );
    },
    freeZoom(item) {
      if (1 <= this.zoomLevel && this.zoomLevel < 4) {
        this.zoomLevel++;
        this.getMapItems({
          coordinateX:
            parseFloat(item.coordinateX) + parseFloat(item.width) / 2,
          coordinateY:
            parseFloat(item.coordinateY) + parseFloat(item.height) / 2,
          zoom_level: this.zoomLevel,
        }).then((result) => {
          this.items = result.result;
          console.log(this.items);
          this.showBoxItems = [];
          this.showBoxItemsGenerate(
            this.indexRows[0].i,
            this.indexRows[0].j,
            this.indexRows[1].i,
            this.indexRows[1].j,
            this.indexRows[2].i,
            this.indexRows[2].j
          );
          this.handleDetails();
        });
      }
    },
    showBoxItemsGenerate(row1i, row1j, row2i, row2j, row3i, row3j) {
      this.row1 = this.items.slice(row1i, row1j);
      this.row2 = this.items.slice(row2i, row2j);
      this.row3 = this.items.slice(row3i, row3j);
      this.row1.forEach((element) => {
        this.showBoxItems.push(element);
      });
      this.row2.forEach((element) => {
        this.showBoxItems.push(element);
      });
      this.row3.forEach((element) => {
        this.showBoxItems.push(element);
      });
      console.log(this.showBoxItems);
      this.indexRows.forEach((element) => {
        console.log(element.i, element.j);
      });
    },
    pan(arrow) {
      const centerItem = this.showBoxItems[4];
      this.showBoxItems = [];
      switch (arrow) {
        case "left": {
          this.indexRows.forEach((element) => {
            element.i--;
            element.j--;
          });
          this.showBoxItemsGenerate(
            this.indexRows[0].i,
            this.indexRows[0].j,
            this.indexRows[1].i,
            this.indexRows[1].j,
            this.indexRows[2].i,
            this.indexRows[2].j
          );
          setTimeout(() => {
            if (this.zoomLevel >= 2) {
              const newCordinateX =
                centerItem.coordinateX - centerItem.width / 2;
              const newCordinateY =
                parseFloat(centerItem.coordinateY) +
                parseFloat(centerItem.height / 2);
              this.getMapItems({
                coordinateX: newCordinateX,
                coordinateY: newCordinateY,
                zoom_level: this.zoomLevel,
              }).then((result) => {
                console.log("res:", result);
                this.items = result.result;
                this.showBoxItems = [];
                this.indexRows.forEach((element) => {
                  element.i++, element.j++;
                });
                this.showBoxItemsGenerate(
                  this.indexRows[0].i,
                  this.indexRows[0].j,
                  this.indexRows[1].i,
                  this.indexRows[1].j,
                  this.indexRows[2].i,
                  this.indexRows[2].j
                );
                this.handleDetails();
              });
            }
          }, 500);

          break;
        }
        case "right":
          {
            this.indexRows.forEach((element) => {
              element.i++;
              element.j++;
            });
            this.showBoxItemsGenerate(
              this.indexRows[0].i,
              this.indexRows[0].j,
              this.indexRows[1].i,
              this.indexRows[1].j,
              this.indexRows[2].i,
              this.indexRows[2].j
            );
            setTimeout(() => {
              if (this.zoomLevel >= 2) {
                const newCordinateX =
                  parseFloat(centerItem.coordinateX) +
                  parseFloat(centerItem.width) +
                  parseFloat(centerItem.width / 2);
                const newCordinateY =
                  parseFloat(centerItem.coordinateY) +
                  parseFloat(centerItem.height / 2);
                this.getMapItems({
                  coordinateX: newCordinateX,
                  coordinateY: newCordinateY,
                  zoom_level: this.zoomLevel,
                }).then((result) => {
                  this.items = result.result;
                  this.showBoxItems = [];
                  this.indexRows.forEach((element) => {
                    element.i--, element.j--;
                  });
                  this.showBoxItemsGenerate(
                    this.indexRows[0].i,
                    this.indexRows[0].j,
                    this.indexRows[1].i,
                    this.indexRows[1].j,
                    this.indexRows[2].i,
                    this.indexRows[2].j
                  );
                  this.handleDetails();
                });
              }
            }, 500);
          }
          break;
        case "top":
          {
            this.indexRows.forEach((element) => {
              element.i = element.i - 5;
              element.j = element.j - 5;
            });
            this.showBoxItemsGenerate(
              this.indexRows[0].i,
              this.indexRows[0].j,
              this.indexRows[1].i,
              this.indexRows[1].j,
              this.indexRows[2].i,
              this.indexRows[2].j
            );
            setTimeout(() => {
              if (this.zoomLevel >= 2) {
                const newCordinateX =
                  parseFloat(centerItem.coordinateX) +
                  parseFloat(centerItem.width / 2);
                const newCordinateY =
                  parseFloat(centerItem.coordinateY) -
                  parseFloat(centerItem.height / 2);
                this.getMapItems({
                  coordinateX: newCordinateX,
                  coordinateY: newCordinateY,
                  zoom_level: this.zoomLevel,
                }).then((result) => {
                  this.items = result.result;
                  this.showBoxItems = [];
                  this.indexRows.forEach((element) => {
                    element.i = element.i + 5;
                    element.j = element.j + 5;
                  });
                  this.showBoxItemsGenerate(
                    this.indexRows[0].i,
                    this.indexRows[0].j,
                    this.indexRows[1].i,
                    this.indexRows[1].j,
                    this.indexRows[2].i,
                    this.indexRows[2].j
                  );
                  this.handleDetails();
                });
              }
            }, 500);
          }
          break;
        case "down":
          {
            this.indexRows.forEach((element) => {
              element.i = element.i + 5;
              element.j = element.j + 5;
            });
            this.showBoxItemsGenerate(
              this.indexRows[0].i,
              this.indexRows[0].j,
              this.indexRows[1].i,
              this.indexRows[1].j,
              this.indexRows[2].i,
              this.indexRows[2].j
            );
            setTimeout(() => {
              if (this.zoomLevel >= 2) {
                const newCordinateX =
                  parseFloat(centerItem.coordinateX) +
                  parseFloat(centerItem.width / 2);
                const newCordinateY =
                  parseFloat(centerItem.coordinateY) +
                  parseFloat(centerItem.height) +
                  parseFloat(centerItem.height / 2);
                this.getMapItems({
                  coordinateX: newCordinateX,
                  coordinateY: newCordinateY,
                  zoom_level: this.zoomLevel,
                }).then((result) => {
                  this.items = result.result;
                  this.showBoxItems = [];
                  this.indexRows.forEach((element) => {
                    element.i = element.i - 5;
                    element.j = element.j - 5;
                  });
                  this.showBoxItemsGenerate(
                    this.indexRows[0].i,
                    this.indexRows[0].j,
                    this.indexRows[1].i,
                    this.indexRows[1].j,
                    this.indexRows[2].i,
                    this.indexRows[2].j
                  );
                  this.handleDetails();
                });
              }
            }, 500);
          }
          break;

        default:
          break;
      }
    },
    selectLocation(item) {
      this.selectedItem = item;
      console.log(this.selectedItem);
      this.mapItemDialog = true;
      this.finallLands = [];
      if(this.token){
        this.getLandsLoading = true;
        this.getUserLands()
          .then((result) => {
            const userLands = result.result;
            console.log("userLands:", userLands);
              this.getLandsLoading = false;
            userLands.forEach((element) => {
              if (
                element.category_id === this.selectedItem.category.id &&
                !element.coordinateX
              ) {
                this.finallLands.push(element);
              }
            })
          })
      }
        
    },
    checkIfWeAreOnBorder(){
      if(this.showBoxItems[3] === null){
            console.log("we saw Left is null");
           this.pan('right');
          }
          if(this.showBoxItems[1]=== null){
            console.log("we saw Top is null");
            this.pan("down")
          }
          if(this.showBoxItems[5]=== null){
            console.log("we saw Right is null");
            this.pan("left")
          }
          if(this.showBoxItems[7]=== null){
            console.log("we saw Down is null");
            this.pan("top")
          }
    },
    assignLandSuccess() {
      this.snackbar = true;
      this.message = "Your land assigned to location successfully";
      this.mapItemDialog = false;
      this.handleDetails();
    },
    assignLandError() {
      this.mapItemDialog = false;
      this.handleDetails();
    },
  },
};
</script>
<style lang="scss" scoped>
.map {
  overflow: auto;
  .map-content {
    overflow: auto;
    width: max-content;
    height: max-content;
    max-width: max-content;
    max-height: max-content;
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0px;
    position: relative;
    overflow: hidden;
    .items {
      .item {
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
    .zoom-box {
      position: absolute;
      top: 10px;
      right: 10px;
      border: 2px solid grey;
      border-radius: 5px;
      background: rgba(56, 56, 56, 0.678);
      z-index: 10;
      button {
        &.disable {
          transform: scale(1);
          cursor: default;
          i {
            color: rgb(160, 160, 160) !important;
          }
        }
      }
    }
    .pan-box {
      position: absolute;
      bottom: 10px;
      right: 10px;
      border: 2px solid grey;
      border-radius: 5px;
      background: rgba(56, 56, 56, 0.678);
      z-index: 10;
      button {
        &:hover {
          transform: scale(1.2);
        }
        &.disable {
          transform: scale(1);
          cursor: default;
          i {
            color: rgb(160, 160, 160) !important;
          }
        }
      }
    }
    .detail-container {
      .detail {
        position: absolute;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.6s ease;
        .v-icon {
          opacity: 0;
        }
        &:hover {
          transition: all 0.6s ease;
          transform: scale(1.4);
          border-color: rgb(255, 0, 0);
          background: rgba(0, 0, 0, 0.858);
          z-index: 10;
          .v-icon {
            color: rgb(255, 255, 255);
            opacity: 1;
          }
        }
      }
      .details-image {
        position: absolute;
        top: 0;
        bottom: 0;
        opacity: 0.4;
      }
    }
  }
}
</style>
